/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~flag-icons/css/flag-icons.min.css";

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;

    h1 {
        margin: 1rem 0 0 0;
        font-size: 6vw;
    }

    h2 {
        margin: 1rem 0 0 0;
        font-size: 4vw;
    }
}

.moving-border {
    border-radius: 6px;
}

.border-wrapper {
	text-decoration: none;
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
	overflow: hidden;
    position: relative;
}

.border-wrapper:before{
	position: absolute;
	top: 2px;
	left: 2px;
	bottom: 2px;
	content: '';
	width: 50%;
}

.border-wrapper span:nth-child(1){
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 3px;
	background: linear-gradient(to right, #e5ea26, #e5ea26);
	animation: borderOne 3s linear infinite;
}


.border-wrapper span:nth-child(2){
	position: absolute;
	top: 0;
	right: 0;
	width: 3px;
	height: 94%;
	background: linear-gradient(to bottom, #e5ea26, #e5ea26);
	animation: borderTwo 3s linear infinite;
	animation-delay: 1s;
}

.border-wrapper span:nth-child(3){
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	background: linear-gradient(to left, #e5ea26, #e5ea26);
	animation: borderThree 3s linear infinite;
}

.border-wrapper span:nth-child(4){
	position: absolute;
	top: 0;
	left: 0;
	width: 3px;
	height: 100%;
	background: linear-gradient(to top, #e5ea26, #e5ea26);
	animation: borderFour 3s linear infinite;
	animation-delay: 1s;
}

@keyframes scaleContainer {
    to {
        transform: scale(0.6);
    }
}

@keyframes scaleLogo {
    to {
        transform: scale(0.92);
    }
}

@media only screen and (min-width: 480px) {
    body {
        h1 {
            font-size: 2.5rem !important;
        }

        h2 {
            font-size: 1.5rem !important;
        }
    }
}

@keyframes borderOne{
	0%{
		transform: translateX(-100%);
	}

	100%{
		transform: translateX(100%);
	}
}

@keyframes borderTwo{
	0%{
		transform: translateY(-100%);
	}

	100%{
		transform: translateY(100%);
	}
}

@keyframes borderThree{
	0%{
		transform: translateX(100%);
	}

	100%{
		transform: translateX(-100%);
	}
}

@keyframes borderFour{
	0%{
		transform: translateY(100%);
	}

	100%{
		transform: translateY(-100%);
	}
}
